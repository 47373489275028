.broadcast_container_main {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 5px;
  // border: 1px solid #ededed;
  -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 26%);
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);
  .video_preview {
    position: relative;
    .video-img {
      margin: 0 auto 25px;
      max-width: 800px;
      display: table;
      max-height: 480px;
    }
  }
}

.cwrap,
.cmove div {
  width: 200px;
  height: 200px;
}

.cwrap {
  color: #fff;
  background: #000;
  overflow: hidden;
  margin: auto;
  border-radius: 100px;
  border: 5px solid white;
  outline: 3px solid #174b7b;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cmove {
  position: relative;
  bottom: 0%;
}

.cmove div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
}

@keyframes countdown {
  0% {
    bottom: 0;
  }
  16% {
    bottom: 0;
  }
  20% {
    bottom: 110%;
  }
  36% {
    bottom: 110%;
  }
  40% {
    bottom: 215%;
  }
  56% {
    bottom: 215%;
  }
  60% {
    bottom: 320%;
  }
  76% {
    bottom: 320%;
  }
  80% {
    bottom: 400%;
  }
  96% {
    bottom: 400%;
  }
  100% {
    bottom: 520%;
  }
}

/*  5 SECONDS Slide*/
.cmove {
  animation: countdown linear 5s;
  animation-fill-mode: forwards;
}
