.account-type-main {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); */
    /* justify-content: space-evenly; */
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 0;

    .account-type-box {
        display: flex;
        gap: 10px;
        align-items: center;
        background: #f4f9fa;
        border: 1px solid #b3c4c6;
        border-radius: 8px;
        padding: 10px;
        // text-align: center;
        // cursor: pointer;
        position: relative;

        .ant-radio-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        .account-icon {
            align-items: center;
            background-color: #fff;
            background-size: 34px 34px;
            border: 1px solid #b3c4c6;
            border-radius: 50%;
            display: flex;
            font-size: 25px;
            height: 64px;
            justify-content: center;
            max-width: 64px;
            width: 64px;
            .fspx-15 {
                font-size: 22px;
            }
        }

        h5 {
            font-weight: 700 !important;
            margin-bottom: 0;
        }

        .account-check {
            font-size: 25px;
            position: absolute;
            top: 0px;
            bottom: 0;
            right: 10px;
            margin: auto 0;
        }

        .ant-radio-wrapper-checked+.account-icon+h5+.account-check {
            display: block !important;

        }
    }
}