.dashboard-box-main {
    background-color: #ffffff;
    padding: 15px 15px;
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 26%);

    .box-top-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        .box-top-hd-left {
            font-size: 13px;
            font-weight: 700;
            font-family: Nunito Sans, sans-serif !important;
        }

        .box-top-hd-right {
            font-size: 12px;
            font-weight: 700;
            font-family: Nunito Sans, sans-serif !important;
        }
    }

    .box-bottom-area {
        // gap: 15px;

        .box-bottom-left {
            .box-bottom-hd1 {
                font-size: 28px;
                font-weight: 700;
                font-family: Nunito Sans, sans-serif !important;
                line-height: initial;
                color: #134a7c;
                &.color-grey {
                    color: grey;
                }
            }

            .box-bottom-hd2 {
                font-size: 13px;
                font-weight: 700;
                font-family: Nunito Sans, sans-serif !important;
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}

.total-hd {
    font-size: 12px;
    font-weight: 700;
    font-family: Nunito Sans, sans-serif !important;
    position: relative;
    padding-left: 14px;
    margin-bottom: 2px;
}

.total-hd:before {
    position: absolute;
    content: "";
    left: 0;
    top: 5px;
    bottom: 0;
    width: 8px;
    height: 8px;
    background-color: #000080;
    border-radius: 50%;
}

.total-hd.sales:before {
    background-color: #c1c1c1;
}


.filters-box-main {
    background-color: #ffffff;
    padding: 15px 15px;
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 26%);

    .filters-top-area {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // gap: 15px;

        .filters-top-hd-left {
            font-size: 13px;
            font-weight: 700;
            font-family: Nunito Sans, sans-serif !important;
        }

        .filters-top-hd-right {
            font-size: 12px;
            font-weight: 700;
            font-family: Nunito Sans, sans-serif !important;
        }

        
    .fltr-btn {
        padding: 6.4px 25px !important;
        height: 40px !important;
        max-width: 100% !important;
        min-width: initial !important;
        text-shadow: none;

        &:hover {
            color: #ffffff;
            background-color: #000;
            border: 1px solid #000;
            box-shadow: none;
            outline: none;
        }

        &:focus {
            color: #ffffff;
            background-color: #000080;
            border: 1px solid #000080;
        }
    }
    .fltr-btn {
    &.m-width-100 {
        min-width: 100px!important;
    }
    }

    }
}


.filters-data-main {
    .filters-data {
        border: 1px solid #d6dee5;
        box-shadow: 0 0 2px 1px #e4e7ed;
        background: #fff;
        margin-bottom: 0px;

        .table_heading {
            background: #000080;
            color: #fff;
        }

        thead {
            tr {
                th {
                    text-transform: none;
                    font-family: Nunito Sans, sans-serif;
                    font-weight: 700;
                }

            }
        }

        tbody {
            tr {
                td {
                    padding: 0.5rem 0.75rem;
                    font-size: 13px;

                    &.sales-event-link {
                        a {
                            color: #000080;

                            &:hover {
                                color: #134a7c;
                            }
                        }
                    }

                    .order-number-btn {
                        cursor: pointer;
                    }
                }

                &:hover {
                    // background-color: #f6f6f7;
                }
            }
        }
    }

}


.dashboard-info-box {
    background-color: #ffffff;
    padding: 5px 10px 5px 35px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 26%);
    position: relative;
    text-align: right;
    &.btm-space-mob {
        margin-bottom: 0;
    }
    .ant-spin {
        color: #000080;
    
        .ant-spin-dot-item {
          background-color: #000080;
        }
      }
    .info-icon-main {
        color: #e4e7ed;
       position: absolute;
       left: 10px;
       top: 0px;
       bottom: 0px;
       margin: auto;
       font-size: 1.7em;
    }
    .total-info-hd {
        font-weight: 600;
        font-size: 11px;
        font-family: "Nunito Sans", sans-serif;
        color: #798892;
        text-transform: uppercase;
    }
    .value-info-hd {
        font-weight: 600;
        font-size: 16px;
        font-family: "Nunito Sans", sans-serif;
        line-height: 1.5;
        color: #575757;
    }
}



// .dashboard-info-box {
//     background-color: #ffffff;
//     padding: 15px 20px 15px 50px;
//     margin-bottom: 24px;
//     border-radius: 5px;
//     box-shadow: 0 0 2px rgb(0 0 0 / 26%);
//     position: relative;
//     text-align: right;
//     .ant-spin {
//         color: #000080;
    
//         .ant-spin-dot-item {
//           background-color: #000080;
//         }
//       }
//     .info-icon-main {
//         color: #e4e7ed;
//        position: absolute;
//        left: 10px;
//        top: 0px;
//        bottom: 0px;
//        margin: auto;
//        font-size: 2.7em;
//     }
//     .total-info-hd {
//         font-weight: 600;
//         font-size: 13px;
//         font-family: "Nunito Sans", sans-serif;
//         color: #798892;
//         text-transform: uppercase;
//     }
//     .value-info-hd {
//         font-weight: 600;
//         font-size: 22px;
//         font-family: "Nunito Sans", sans-serif;
//         line-height: 1.5;
//         color: #575757;
//     }
// }


.separator-dashboard {
    width: 100%;
    height: 1px;
    display: inline-block;
    background-color: #e9e9e9;
    margin: 0px 0 5px 0;
}
@media only screen and (max-width: 1600px) and (min-width: 1200px) {
.dashboard-page .col-xl {
    flex: 0 0 20%;
    max-width: 20%;
}
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {

    // @media (max-width: 1500px) {
    .laptop-width-box {
        flex: 0 0 33% !important;
        max-width: 33% !important;
    }

    .laptop-width-box2 {
        flex: 0 0 66% !important;
        max-width: 66% !important;
    }

    .laptop-width-box3 {
        flex: 0 0 33% !important;
        max-width: 33% !important;
    }
}


@media only screen and (max-width: 1024px) {
    .filters-box-main {
        .filters-top-area {
            &.break-mob-full {
                flex-direction: column;
                align-items: start;
            }
        }
    }
}

@media only screen and (max-width: 991x) {
.dashboard-info-box {
    &.btm-space-mob {
        margin-bottom: 15px;
    }
}
}

@media only screen and (max-width: 767px) {

    .dashboard-box-main {
        margin-bottom: 15px;
    }
    .dashboard-info-box {
        padding: 5px 10px 5px 40px;
        margin-bottom: 10px;
        .value-info-hd {
            font-size: 13px;
        }
        .total-info-hd {
            font-size: 10px;
        }
    }
.dashboard-page .col-6:nth-child(even){
    padding-left: 5px;
}
.dashboard-page .col-6:nth-child(odd){
    padding-right: 10px;
}
}

