@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500;600;700&display=swap");

@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.eot");
  /* IE9*/
  src: url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.woff")
      format("woff"),
    /* chrome、firefox */
      url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.svg#Segoe UI")
      format("svg");
  /* iOS 4.1- */
}
@font-face {
  font-family: "SegoeUIBold";
  src: url("../fonts/segoui/FontsFreeNetSegoeUIBold.eot");
  src: url("../fonts/segoui/FontsFreeNetSegoeUIBold.eot")
      format("embedded-opentype"),
    url("../fonts/segoui/FontsFreeNetSegoeUIBold.woff2") format("woff2"),
    url("../fonts/segoui/FontsFreeNetSegoeUIBold.woff") format("woff"),
    url("../fonts/segoui/FontsFreeNetSegoeUIBold.ttf") format("truetype"),
    url("../fonts/segoui/FontsFreeNetSegoeUIBold.svg#FontsFreeNetSegoeUIBold")
      format("svg");
}
@font-face {
  font-family: "SegoeUI_Medium";
  src: url("../fonts/segoui//SegoeUI_Medium.eot");
  src: url("../fonts/segoui//SegoeUI_Medium.eot") format("embedded-opentype"),
    url("../fonts/segoui/SegoeUI_Medium.woff2") format("woff2"),
    url("../fonts/segoui/SegoeUI_Medium.woff") format("woff"),
    url("../fonts/segoui/SegoeUI_Medium.ttf") format("truetype"),
    url("../fonts/segoui/SegoeUI_Medium.svg#SegoeUI_Medium") format("svg");
}

html {
  font-weight: 100;
  font-size: 14px;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5715;
  // background-color: #fff;
  background-color: #f9f9f9;
  font-feature-settings: "tnum", "tnum";
  overflow-x: hidden;
  text-align: left;
}

body {
  font-family: "Segoe UI" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 600 !important;
  margin-bottom: 0.5em;
}
.ff-nunito {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 600 !important;
}
.form-control {
  min-height: 45px;
}
.btn {
  padding: 0.375rem 1rem;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
.modal-100w {
  max-width: 100%;
}
.modal-90w {
  max-width: 90%;
}
.modal-80w {
  max-width: 80%;
}
.modal-70w {
  max-width: 70%;
}
.form-control:focus {
  box-shadow: none;
}
.toast-success {
  background-color: #07bc0c;
}
.toast-success .ant-notification-notice-message {
  color: #fff;
  position: relative;
  top: 4px;
}
.toast-success .anticon.ant-notification-notice-icon-success {
  color: #fff;
}
.toast-success .ant-notification-notice-close {
  color: #fff;
}
.toast-error {
  background-color: #e74c3c;
}
.toast-error .ant-notification-notice-message {
  color: #fff;
  position: relative;
  top: 4px;
}
.toast-error .anticon.ant-notification-notice-icon-error {
  color: #fff;
}
.toast-error .ant-notification-notice-close {
  color: #fff;
}

.stream-screen {
  position: relative;
}
.stream-screen .generic-style .sidebar-main,
.stream-screen .prev-next-link {
  display: none;
}
.stream-screen .generic-style .ant-layout {
  margin-left: 0px;
}

/* Absolute Center Spinner */
.sync_loading {
  position: fixed;
  z-index: 999;
  height: 6em;
  width: 14em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

/* Transparent Overlay */
.sync_loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
  z-index: -1;
}

/* :not(:required) hides these rules from IE9 and below */
.sync_loading:not(:required) {
  /* hide "loading..." text */
  // font: 0/0 a;
  // color: rgb(255 255 255 / 75%);
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.loading_text {
  // color: #fff;
  // position: relative;
  top: 66px;
  position: relative;
  // left: -39px;
  color: #d6dee5;
  font-size: 26px;
}
.sync_loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  position: relative;
  left: 95px;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}
.sync_loading .spinner-grow {
  position: relative;
  width: 0.7rem;
  height: 0.7rem;
  position: relative;
  top: -5px;
  left: 6px;
  margin-right: 4px;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
