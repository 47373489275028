.product-box-main {
  padding-bottom: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .product-image {
    width: 100%;
    height: 110px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    border: 1px solid #efefef;

    img {
      object-fit: cover;
      max-width: 100%;
      // border-radius: 5px;
      // height: 100%;
      width: 100%;
      object-position: top;
    }
  }

  .product-content {
    // min-height: 67px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      color: #000000;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 5px;
      font-family: Nunito Sans, sans-serif !important;
    }

    h4 {
      color: grey;
      font-size: 13px;
      margin-bottom:
        5px;
    }
  }
}