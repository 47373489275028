.contact_content {
    width: 100%;
    .side-hd {
      font-size: 20px;
      font-weight: 800 !important;
      margin-top: 48px;
      margin-bottom: 20px;
    }
    ul.side-list {
      list-style-type: none;
      margin: 0 0 20px 0;
      padding: 0;
      li {
        width: 100%;
        padding: 7px 0;
        a {
          color: #7b8994;
          padding-bottom: 0px;
          display: block;
          &:hover {
            color: #000000;
          }
          &.active {
            color: #000;
            font-weight: 800;
            font-family: "SegoeUIBold";
          }
        }
      }
    }
    .list-simple {
      padding-left: 20px;
      li {
        margin-bottom: 10px;
        color: #252525;
        strong {
          font-family: "SegoeUIBold";
        }
      }
    }
    .privacy_header {
      // text-align: center;
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
      -webkit-box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
      -moz-box-shadow: 0 4px 8px 0 rgba(10, 22, 70, 0.15);
      background-color: #fff;
      padding: 30px;
      h2 {
        color: #000080;
        font-weight: 800 !important;
        font-size: 120px;
        margin-bottom: 40px;
      }
      .privacy-img {
        margin-bottom: 40px;
        max-width: 100%;
        height: auto;
      }
      h3 {
        color: #252525;
        font-weight: 800 !important;
        font-size: 30px;
        margin-bottom: 10px;
      }
      h5 {
        color: #252525;
        font-weight: 800 !important;
        font-size: 22px;
        line-height: 2rem;
        margin-bottom: 10px;
        padding-top: 10px;
      }
      p {
        color: #252525;
        font-weight: 400 !important;
        strong {
          font-family: "SegoeUIBold";
        }
        // line-height: initial;
      }
      // a {
      //     background-color: #000080;
      //     color: #FFF;
      //     font-family: Nunito Sans, sans-serif !important;
      //     padding: 10px 35px;
      //     font-weight: 700;
      //     border-radius: 37px;
      //     display: inline-block;
      //     margin-top: 45px;
      //     font-size: 16px;
      //     &:hover{
      //         background-color: #000;
      //     }
      // }

      .faq-container-ift {
        .ant-collapse {
          border: none;
          background-color: transparent;
          .ant-collapse-item {
            .ant-collapse-header {
              padding-left: 0;
              padding-top: 20px;
              padding-bottom: 20px;
              .ant-collapse-header-text {
                font-family: "Nunito Sans", sans-serif !important;
                font-size: 16px;
                color: #252525;
                font-weight: 700;
              }
              .ant-collapse-expand-icon {
                .ant-collapse-arrow {
                  font-size: 20px;
                }
              }
            }
            .ant-collapse-content {
              border-top: none;
              .ant-collapse-content-box {
                padding: 5px 0;
              }
            }
          }
        }
      }
    }
    .ant-btn-primary {
        background-color: #000080;
        border-color: #000080;
        color: #fff;
        font-family: Nunito Sans, sans-serif !important;
        padding: 10px 35px;
        font-weight: 700;
        border-radius: 37px;
        height: auto;
        font-size: 16px;
        &:hover {
          background-color: #000;
        }
      }

  }


  @media only screen and (max-width: 767px) {
    .contact_content {
        width: 100%;
        .side-hd {
          margin-top: 0px;
          font-size: 16px;
          margin-bottom: 10px;
        }
        ul.side-list {
            li {
              padding: 3px 0;
            }
          }
    }
  }