.dashboard-content-full {

  .post-analytics-tab form .row .col-md-6 p {
    margin-bottom: 0.5rem;
  }

  .upload_area_2.uploadWidth {
    width: 90% !important;
    background: #f3f2f1;
    border: dashed 2px #000080;
    border-radius: 15px;
    &.full-width {
      width: 100% !important;

    }
  }

  .upload_area_2.uploadWidth:hover {
    border-color: #000080 !important;
  }

  .summary_container_main {
    padding-left: 0;
    padding-right: 0;
    margin: 0;

    .ant-picker-range {
      width: 100%;
      display: flex !important;
    }

    .summary_box_main {
      // padding-right: 15px;
      // padding-left: 15px;

      .summary_block_profile {
        margin-bottom: 24px;
        background-color: #FFF;
        box-shadow: 0px 0px 2px 1px #e4e7ed;
        // min-height: 445px;

        .summary_content_profile {
          transition: opacity .2s ease-out;
          margin: 0 auto;
          padding: 20px;
          width: 100%;
          overflow-x: visible;

          .card {
            box-shadow: none !important;
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            margin-top: 1rem;
            margin-bottom: 0;
            padding-bottom: 0;
          }

        }

      }

    }
  }

  .any-post-img-col {
    display: flex;
  }

  .any-post-image {
    position: relative;
    max-width: 100%;
    flex: 0 0 100%;

    .any-image-box {
      position: absolute;
      left: 1px;
      right: 1px;
      top: 1px;
      bottom: 1px;
      border: 1px solid;
      border-color: #f6f6f6 #f3f3f3 #eee;

      .any-image-box-iner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        margin: 0;
        display: block;

        img {
          display: block;
          height: 100%;
          width: 100%;
          border-radius: 0px !important;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: 50% 0;
          object-position: 50% 0;
        }
      }
    }

  }

  .any-post-image:after {
    content: "";
    padding-top: 100%;
    display: block;
  }

  .fltr-h {
    height: 44px;
    margin-right: 20px !important;
  }

  .fltr-h:last-child {
    margin-right: 0 !important;
  }

  .fltr-h:hover {
    color: #FFF !important;
  }

  .fltr-hpr {
    height: 44px;
    max-width: 100%;
    min-width: 50%;
  }

  .fltr-hpr:last-child {
    margin-right: 0 !important;
  }

  .fltr-hpr:hover {
    color: #FFF !important;
  }

  .post-analytics-tab form .row {
    align-items: flex-end;
  }

  .nav-tabs {
    background: transparent;
    border-bottom: 1px solid #c8c8c8;
    padding: .75rem 2rem
  }

  hr {
    border-color: #ccc;
    ;
  }

  .card {
    box-shadow: 0px 0px 3px #ccc !important;
    border: none;
  }

  .tab-content>.tab-pane {
    padding: 1.5rem 0;
  }

  .nav-tabs>.nav-item {
    flex-grow: 0 !important;
    text-align: left;
    border-bottom: none;


    .nav-link.active {
      border: none !important;
      font-weight: normal;
      background-color: #000080 !important;
      color: #FFF;
    }

    .nav-link {
      background: none !important;
      padding: .75rem 1.5rem;
      font-size: 1rem;
      border-radius: 2rem;
      display: block;
      text-align: -webkit-match-parent;
      line-height: 16px;
      margin-bottom: 0;
      font-family: 'Nunito Sans', sans-serif !important;
      font-weight: 600 !important;
    }

    .nav-link.active:hover {
      color: #FFF;
    }
  }

  .ant-table table {
    width: 100%;
    border: 1px solid #c8c8c8;
  }

  .ant-table-thead>tr>th {
    border-bottom: 1px solid #c8c8c8 !important;
    font-weight: 600;
    text-align: center;
  }


  .ant-table-tbody>tr>td:first-child {
    border-right: 1px solid #c8c8c8;
  }

  .ant-table-tbody>tr>td:last-child {
    text-align: center;

  }

  .ant-table-pagination.ant-pagination {
    justify-content: end;
  }


  .ant-table-tbody>tr>td {
    padding: 10px;
    border-bottom: 1px solid #c8c8c8;
  }

  .ant-table table {
    border-bottom: none;
  }

  /*Antd Images */

  .ant-table img {
    width: 100%;
  }

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 10px 10px !important;
  }

  /*hight Chart */

}

.analytics-summery-box.col-md-4 {
  padding-left: 15px;
  padding-right: 15px;
}

.summary_container_main.col-md-6,
.summary_container_main.col-md-8 {
  padding-left: 15px;
  padding-right: 15px;
}


/**************analytic-box******************/
.analytic-box {
  margin-bottom: 20px;
  padding: 15px;
}

.any_bx.analytic-box {
  padding-bottom: 40px;
}

.analytic-box .media-image {
  border: solid 1px #ccc;
  padding: 2px;
}

.count-main-box {
  /* border-top: 1px solid #c8c8c8; */
  /* margin-top: 15px !important; */
  padding-top: 15px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.analytic-box .count-box {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #ede9e9;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 14px;
}

.analytic-box .count-box:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.analytic-box .count-box .count {
  font-size: 1rem;
}

.analytic-box .count-box .count-title {
  color: #798892;
  font-size: 1rem;
}

.analytics-loading {
  display: flex !important;
  align-items: center !important;
  height: 50vh !important;
}

.analytic-box .analytic-caption {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.cam-buttons.col-12 {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}

.cam-buttons.col-12 .btn {
  padding: 6px 0;
  background-color: gray;
  color: #fff;
  flex: 100%;
  max-width: 100%;
  border-radius: 0;
  border-right: solid 1px #fff;
}

.cam-buttons.col-12 .btn i {
  margin-right: 0.2rem;
}

.cam-buttons.col-12 .btn:last-child {
  border: none;
}

.cam-buttons.col-12 .btn:hover {
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}

.connections button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connections button i {
  font-size: 20px;
  text-align: left;
}

.connections button a {
  color: white;
}

.connect-page-header {
  background-color: #f1e7e7;
  /* display: flex; */
  padding: 0 15px;
}

.img-connect {
  /* margin-left: 28px; */
  width: 180px;
  height: 50px;
}

.btn-div {
  position: absolute;
  right: 28px;
}

.btn-connect {
  margin-top: 8px;
}

.label-insta {
  margin-top: 4px;
  color: crimson;
  text-align: right;
}

.auth-logo .logo {
  width: 180px;
  height: 90px;
}

.analytic-box.prod-box {
  margin-bottom: 0px;
  padding: 5px 20px 0px 20px;
}

.product-url-link {
  width: 76%;
  word-break: break-all;
  text-align: right;
}


.drg-drop-main {
  display: flex;
  align-items: center;
}

.drag-drop-ift {
  border: none !important;
  overflow: auto !important;
  margin: 0 !important;
}

.upload_area {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.upload_area_2 {
  min-height: 200px;
  background: #f0f3fd;
  border: dashed 2px #9ea4b8;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 90%;
  padding: 20px;
  flex-direction: column;

  .fa-cloud-arrow-up {
    font-size: 50px;
    margin: 15px 0;
    color: #495057;
  }
}

.upload_area_3 {
  width: 90%;
  margin: 0 auto;
  &.full-area {
    width: 100%;

  }
}

.upload_area_2 h4 {
  font-size: 1.3rem;
  color: #575757;
  margin-bottom: 10px;
}

.drag-drop-ift .dzu-submitButton {
  font-family: Nunito Sans, sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  margin: 0 5px 0;
  border-radius: 3px;
  color: #ffffff;
  background-color: #000080;
  border: 1px solid #000080;
  min-width: 120px;
  height: 40px;
  padding: 6.4px 25px;
  align-items: center;
  filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));



}

.preview-box-media {
  background: #f0f3fd;
  border: dashed 1px #9ea4b8;
  padding: 10px !important;
  width: 90%;
  display: flex;
  border-radius: 5px;
  word-break: break-all;
}

.pre-img-upload {
  width: 66px;
  height: 66px;
  position: relative;
}

.pre-img-upload img {
  width: 66px;
  height: 66px;
  box-shadow: 0 0 0 3px rgb(126 125 125 / 40%);
  border-radius: 50%;
}

.pre-content-upload {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 20px;
}

.pro-brar-ift {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.pro-brar-ift .progress {
  width: 95%;
  height: 0.65rem;
  font-size: 0.65rem;
}

.pro-brar-ift .progress .progress-bar {
  background-color: #000080;
}

.pro-brar-ift span.glyphicon,
.pro-brar-ift .fa-xmark {
  top: -4px;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  font-weight: bold;
}

.glry-img-name {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  margin-top: 10px;
}

.glry-img-size {
  font-family: Montserrat, sans-serif;
  font-size: 0.9rem;
}