.social_box_main {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);
  position: relative;
}

.conn-count {
  font-size: 1rem;
}

.connection-status-badge-red {
  // position: absolute;
  right: 10px;
  background: red;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  top: 11px;
  font-size: 9pt;
  font-weight: 600;
  font-family: "SegoeUIBold";
}

.connection-status-badge-grey {
  // position: absolute;
  right: 10px;
  background: grey;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  top: 11px;
  font-size: 9pt;
  font-weight: 600;
  font-family: "SegoeUIBold";
}

.connection-status-topbar-badge-green {
  position: absolute;
  right: 280px;
  background: green;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  top: 11px;
  font-size: 9pt;
  font-weight: 600;
  font-family: "SegoeUIBold";
}



.social-header-hd-area {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
  .step-hd {
    display: inline-block;
    background: #134a7c;
    padding: 4px 10px;
    margin-bottom: 0px;
    border-radius: 5px;
    color: white;
    font-size: 13px;
    font-weight: 700!important;
    font-family: "Nunito Sans", sans-serif !important;
  }
  .connection-status-badge-green {
    // position: absolute;
    // right: 280px;
    // top: 11px;
    background: green;
    color: #fff;
    padding: 4px 10px;
    border-radius: 5px;
    // font-size: 13px;
    font-size: 9pt;
    font-weight: 700!important;
    font-family: "Nunito Sans", sans-serif !important;
  }
}

.total-orders-batches {
  display: flex;
  align-items: center;
  .connection-status-badge-grey {
    // position: absolute;
    // right: 280px;
    // top: 11px;
    background: grey;
    color: #fff;
    padding: 4px 10px;
    border-radius: 5px;
    // font-size: 13px;
    font-size: 9pt;
    font-weight: 400!important;
    font-family: "Nunito Sans", sans-serif !important;
  }
  .connection-status-badge-red {
    // position: absolute;
    right: 10px;
    background: red;
    color: #fff;
    padding: 4px 10px;
    border-radius: 5px;
    top: 11px;
    font-size: 9pt;
    font-weight: 400;
    font-family: "SegoeUIBold";
  }
  .connection-status-badge-green {
    // position: absolute;
    background: green;
    color: #fff;
    padding: 4px 10px;
    border-radius: 5px;
    // font-size: 13px;
    font-size: 9pt;
    font-weight: 400!important;
    font-family: "Nunito Sans", sans-serif !important;
  }
  .connection-status-badge-orange {
    // position: absolute;
    background: orange;
    color: #fff;
    padding: 4px 10px;
    border-radius: 5px;
    // font-size: 13px;
    font-size: 9pt;
    font-weight: 400!important;
    font-family: "Nunito Sans", sans-serif !important;
  }
}


.connected-status-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  .exclamation-link {
    font-size: 16px;
    svg {
      color: #134a7c;
    }
}
}