.login-page {
  background-color: #ffffff;
}
.login-main {
  height: 100vh;
  background-color: lightgrey;
  display: flex;
  align-items: center;

  .login-left {
    flex: 0 0 70%;
    flex-direction: column;
    max-width: 70%;
    padding: 3rem;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-image: url(../images/signin-bg.jpg);
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(22, 43, 76, .8);
      z-index: 0;
    }

    .login-left-content {
      position: relative;
      z-index: 100;
      padding: 3rem;

      .intro-main {
        h1 {
          color: #ffffff;
          font-size: 1.5rem;
          letter-spacing: .05em;
          margin-bottom: 0;
          padding: 0;
        }

        h2 {
          color: #bbc6ff;
          font-size: 4rem;
          font-weight: 700!important;
          line-height: 87px;
          letter-spacing: .1em;
          margin-bottom: 0;
          padding: 0;
        }

        p {
          color: #ffffff;
          font-size: 1.5rem;
          letter-spacing: .05em;
          margin-bottom: 0;
          padding: 0;
        }
      }
    }
  }

  .login-right {
    flex: 0 0 30%;
    flex-direction: column;
    max-width: 30%;
    background-color: #ffffff;
    padding: 3rem;
    height: 100vh;
    display: flex;
    justify-content: center;
    .login-right-content {
      padding:0 3rem;
    .logo-login {
      max-width: 200px;
      margin-bottom: 50px;
    }
    .login-hd {
      color: #252525;
      font-size: 25px;
      letter-spacing: 2px;
      font-weight: 800 !important;
      margin-bottom: 30px;
      padding: 0;
    }
  }
  }
}

.form-styling {
.login-form-forgot {
  float: right;
  color: grey;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.signin-link {
  color: grey;
  font-size: 15px;
  // color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.login-btn {
  display: table;
  margin: 10px auto 0 auto;
  &.reset-btn {
    font-weight: 600;
  }
}
}



@media only screen and (max-width: 1440px) {
  .login-main {
    .login-left {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .login-right {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .login-main {
    .login-left {
      flex: 0 0 50%;
      max-width: 50%;
      .login-left-content {
        padding: 2rem;
  
        .intro-main {
          h1 {
            color: #ffffff;
            font-size: 1.5rem;
            letter-spacing: .05em;
            margin-bottom: 0;
            padding: 0;
          }
  
          h2 {
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 87px;
            letter-spacing: .05em;
          }
  
          p {
            font-size: 1rem;
            letter-spacing: .05em;
          }
        }
      }
    }
    .login-right {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
@media only screen and (max-width: 991px) {
  .login-main {
    display: initial;
    align-items: initial;
    .login-left {
      height: initial;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 2rem;
      .login-left-content {
        padding: 0rem;
  
        .intro-main {
          h1 {
            color: #ffffff;
            font-size: 1.5rem;
            letter-spacing: .05em;
            margin-bottom: 0;
            padding: 0;
          }
  
          h2 {
            font-size: 2rem;
            font-weight: 700;
            line-height: 50px;
            letter-spacing: .05em;
          }
        }
      }

    }
    .login-right {
      height: initial;
      flex: 0 0 100%;
      max-width: 100%;
      padding:2rem;
      .login-right-content {
        padding: 0;
        .logo-login {
          max-width: 150px;
          margin-bottom: 30px;
      }
    }
    }
  }
}

@media only screen and (max-width: 767px) {
  .login-main {
    .login-left {
      padding: 1rem ;
      .login-left-content {
        padding: 0rem;
  
        .intro-main {
          h1 {
            color: #ffffff;
            font-size: 1rem;
            padding: 0;
          }
  
          h2 {
            font-size: 1.5rem;
            line-height: initial;
          }
          p {
            font-size: 0.875rem;
          }
        }
      }

    }
    .login-right {
      padding: 1.5rem 1rem 1rem 1rem;
      .login-right-content {
        padding: 0;
        .logo-login {
          max-width: 150px;
          margin:0 auto 20px;
      }
      .login-hd {
        text-align: center;
        font-size: 20px;
        margin-bottom: 15px;
      }
    }
    }
  }
}