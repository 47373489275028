@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@500&display=swap');


@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.eot");
  /* IE9*/
  src: url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.woff2") format("woff2"),
    /* chrome、firefox */
    url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.woff") format("woff"),
    /* chrome、firefox */
    url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.svg#Segoe UI") format("svg");
  /* iOS 4.1- */
}

@font-face {
  font-family: 'SegoeUIBold';
  src: url('../fonts/segoui/FontsFreeNetSegoeUIBold.eot');
  src: url('../fonts/segoui/FontsFreeNetSegoeUIBold.eot') format('embedded-opentype'),
    url('../fonts/segoui/FontsFreeNetSegoeUIBold.woff2') format('woff2'),
    url('../fonts/segoui/FontsFreeNetSegoeUIBold.woff') format('woff'),
    url('../fonts/segoui/FontsFreeNetSegoeUIBold.ttf') format('truetype'),
    url('../fonts/segoui/FontsFreeNetSegoeUIBold.svg#FontsFreeNetSegoeUIBold') format('svg');
}

@font-face {
  font-family: 'SegoeUI_Medium';
  src: url('../fonts/segoui/SegoeUI_Medium.eot');
  src: url('../fonts/segoui//SegoeUI_Medium.eot') format('embedded-opentype'),
    url('../fonts/segoui/SegoeUI_Medium.woff2') format('woff2'),
    url('../fonts/segoui/SegoeUI_Medium.woff') format('woff'),
    url('../fonts/segoui/SegoeUI_Medium.ttf') format('truetype'),
    url('../fonts/segoui/SegoeUI_Medium.svg#SegoeUI_Medium') format('svg');
}



html {
  font-weight: 100;
  font-size: 14px;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: "tnum", "tnum";
  overflow-x: hidden;
  text-align: left;
}

.unSelectedMenu {
  font-weight: 700 !important;
}

body {
  // font-family: "Segoe UI" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 600 !important;
  margin-bottom: 0.5em;
}

.form-control {
  min-height: 45px;
}

.btn {
  padding: 0.375rem 1rem;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

.modal-100w {
  max-width: 100%;
}

.modal-90w {
  max-width: 90%;
}

.modal-80w {
  max-width: 80%;
}

.modal-70w {
  max-width: 70%;
}

.form-control:focus {
  box-shadow: none;
}