.dp_cont {
  display: flex;
  align-items: center;
  margin-bottom: 2.9rem;
}

.dp_cont.mb-5 {
  margin-bottom: 1rem !important;
}

.dp_cont > span {
  width: 76px;
  height: 76px;
  position: relative;
  text-align: center;
}

.dp_cont > span strong {
  font-family: "SegoeUIBold";
  display: block;
  padding-top: 5px;
  position: absolute;
  width: 100%;
}

.dp_cont > span img {
  width: 76px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgb(126 125 125 / 40%);
}

.dp_buttons {
  margin-left: 20px;
  width: 100%;
  display: flex;
}

.dp_buttons button {
  margin-bottom: 0;
  width: 100%;
}

.dp_buttons button:last-child {
  margin-right: 0 !important;
}


.profile-btn {
  font-family: Nunito Sans, sans-serif !important;
  margin: 0 5px 0;
  border-radius: 3px;
  color: #ffffff;
  background-color: #000080;
  border: 1px solid #000080;
  width: 100%;
  height: 35px;
  line-height: 30px;
  padding: 2px 0px 0px 0px;
  align-items: center;
  filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
  font-size: 13px;

  &:hover {
    color: #ffffff;
    background-color: #000;
    border: 1px solid #000;
  }

  &:focus {
    color: #ffffff;
    background-color: #000;
    border: 1px solid #000;
  }

  &.color-secondary:disabled {
    color: #ffffff;
    background-color: #000080;
    border: 1px solid #000080;
    opacity: .65;

    &:hover {
      opacity: .65;
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
    }

    &:focus {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
    }
  }

  label {
    cursor: pointer;
  }
}


.mt-98 {
  margin-top: 98px;
}

.profile-hd-area {
  margin-bottom: 0px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  gap: 0;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 5px;

  .profile-hd {
    line-height: 1.2;
    padding-bottom: 5px;
    font-size: 18px;
    font-family: "Nunito Sans", sans-serif !important;
    font-weight: 600 !important;
  }

  .followers-box-main {
    display: flex;
    align-items: center;
    gap: 0px;
    padding-bottom: 5px;

    .followers-txt {
      font-family: "Nunito Sans", sans-serif !important;
      font-weight: 700 !important;
      // min-width: 145px;
    }

    .followers-box {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: "Nunito Sans", sans-serif !important;

      // min-width: 72px;
      .followers-icon {
        font-weight: 400 !important;
        color: grey;
        font-size: 14px;
        margin: -3px 0 0 0;
      }

      .followers-count {
        font-weight: 600 !important;
        color: grey;
        font-size: 16px;
        // min-width: 40px;
      }

    }
  }
}

.social-setup-hd-area {

  .followers-box-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    padding-bottom: 5px;
    width: 100%;

    .followers-txt {
      font-family: "Nunito Sans", sans-serif !important;
      font-weight: 600 !important;
      font-size: 1.2rem;
    }

    .followers-box {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: "Nunito Sans", sans-serif !important;

      // min-width: 72px;
      .followers-icon {
        font-weight: 400 !important;
        color: grey;
        margin: -3px 0 0 0;
        svg {
          font-size: 25px;
        }
      }

      .followers-count {
        font-weight: 600 !important;
        color: grey;
        font-size: 1.25rem;
        margin-left: 3px;
      }
    }
  }
}
.basic-hd-top {
  padding-bottom: 5px;
  font-size: 20px;
  border-bottom:1px solid #e1e1e1;
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 700!important;
  &.top-space {
    margin-top: 50px;
  }
}

.basic-hd2 {
  padding-bottom: 5px;
  font-size: 1.3rem;
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 600!important;
  display: inline-block;
  border-bottom: 2px solid #d3d3d3;
}


@media only screen and (max-width: 1250px) {
  .mt-98 {
    margin-top: 0px;
  }

  .profile_box_main.col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
.basic-hd-top {
  &.top-space {
    margin-top: 5px;
  }
}
}